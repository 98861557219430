import { Card, CardProps, Skeleton, Typography } from "antd";
import { UserRole, useCurrentUser } from "hooks/useCurrentUser";
import UserAvatar from "./UserAvatar";
import UserRoleBadge from "./UserRoleBadge";
import { useUser } from "./hooks";

interface UserInfoCardProps extends Omit<CardProps, "children" | "type"> {
    userId: number;
    showClientBadge?: boolean;
}

const UserInfoCard = ({
    userId,
    showClientBadge,
    style,
    ...props
}: UserInfoCardProps) => {
    const { data: currentUser } = useCurrentUser();

    const { data: user, status } = useUser({
        variables: { id: userId },
        gcTime: Infinity,
        staleTime: 3_600_000, // 1 hour
    });

    if (status === "error") {
        return null;
    }

    return (
        <UserRoleBadge user={user} showClientBadge={showClientBadge}>
            <Card
                {...props}
                style={{
                    backgroundColor: "#fafafa",
                    width: 300,
                    ...style,
                }}
                type="inner"
            >
                <Skeleton
                    loading={status === "pending"}
                    title={false}
                    active
                    avatar
                >
                    <Card.Meta
                        title={
                            <Typography.Text ellipsis>
                                {user?.first_name} {user?.last_name}
                                {(currentUser?.role ?? UserRole.Client) >=
                                UserRole.Admin
                                    ? " #" + user?.id
                                    : ""}
                            </Typography.Text>
                        }
                        description={
                            <Typography.Text ellipsis>
                                {user?.email}
                            </Typography.Text>
                        }
                        avatar={<UserAvatar size="large" userId={userId} />}
                        style={{ alignItems: "center" }}
                    />
                </Skeleton>
            </Card>
        </UserRoleBadge>
    );
};
export default UserInfoCard;
