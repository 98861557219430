import { Button, Result } from "antd";
import type { ResultStatusType } from "antd/lib/result";
import axios from "axios";
import { Link, useRouteError } from "react-router-dom";

export default function ErrorElement() {
    const error = useRouteError();
    if (axios.isAxiosError(error) && error.response) {
        return (
            <Result
                status={getStatus(error)}
                title={`${error.response.status} - ${error.response.statusText}`}
                subTitle={error.response.data.detail}
                extra={
                    <Link to="/">
                        <Button type="primary">Back Home</Button>
                    </Link>
                }
            />
        );
    }
    throw error;
}

function getStatus(error: any): ResultStatusType {
    if (
        axios.isAxiosError(error) &&
        error.response &&
        [403, 404, 500].includes(error.response.status)
    ) {
        return error.response.status.toString() as ResultStatusType;
    }
    return "error";
}
